<template>
  <div class="common-bg">
    <van-nav-bar title="我的藏品" left-arrow @click-left="onClickLeft" />
    <template v-if="list.length > 0">
      <div v-for="item in list" :key="item.id">
        <div class="stock-card">
          <div class="card-li">
            <span class="text-grey">名称：</span>
            <span class="font-weight">{{ item.productTitle }}</span>
          </div>
          <div class="card-li">
            <span class="text-grey">数量：</span>
            <span class="font-weight text-warning">{{ item.num }}</span>
          </div>
          <div class="card-li">
            <span class="text-grey">购买时间：</span>
            <span class="font-weight">{{ item.createTime }}</span>
          </div>
          <div class="card-li">
            <span class="text-grey">解锁时间：</span>
            <span class="font-weight">{{ item.unlockTime }}</span>
          </div>
        </div>
      </div>
    </template>
    <div class="text-center padded-t-15" v-else>暂无记录</div>
  </div>
</template>

<script>
import { getUserProductList } from '@/utils/api.js'
export default {
  data() {
    return {
      list: []
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      getUserProductList(3).then((res) => {
        this.list = res.data
      })
    },
    onClickLeft() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.stock-card {
  padding: 15px;
  background: hsla(0, 0%, 100%, 0.6);
  border-radius: 8px;
  font-size: 14px;
  margin: 10px;
  .card-li {
    padding: 3px 0;
  }
}
</style>
